import { ErrorPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const ErrorPanelWithMargin = styled(ErrorPanel)`
  margin-bottom: ${spacing(4)};
  margin-top: ${spacing(-2)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(-4)};
  `}
`;
